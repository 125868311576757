import React from "react";
import { useTranslation } from "react-i18next";
//import img from "../assets/img.jpg";

const Home = () => {
  const { t } = useTranslation();

  return (
    <div
      name="home"
      className="h-screen w-full bg-gradient-to-b from-black via-black to-gray-800 pb-23"
    >
      <div className="max-w-screen-lg mx-auto   h-full ">
        <div className="flex flex-col justify-center h-full">
          <h2 className="text-3xl sm:text-5xl font-bold text-white pt-52">
            {t('heloMessage')}
          </h2>
          <p className="text-gray-500 py-1 max-w-md">{t("message1")}</p>
          <p className="text-gray-500 py-1 max-w-md">{t("message2")}</p>
          <p className="text-gray-500 py-1 max-w-md">{t("message3")}</p>
          <p className="text-gray-500 py-1 max-w-md">{t("message4")}</p>
          <p className="text-gray-500 py-1 max-w-md">{t("message5")}</p>
          <p className="text-gray-500 py-1 max-w-md">{t("message6")}</p>
          <p className="text-gray-500 py-1 max-w-md">{t("message7")}</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
